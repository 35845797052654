import {Injectable} from '@angular/core';
import {Contact, Customer, Extern, Project} from '../../model';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ExternContactSelectDialogComponent} from './extern-contact-select-dialog.component';

@Injectable()
export class ExternContactSelectDialog {

  constructor(private matDialog: MatDialog) {
  }

  open(extern: Extern[]): Observable<Contact> {
    return this.matDialog.open(ExternContactSelectDialogComponent,
      {
        width: '750px', disableClose: false,
        data: {extern}
      })
      .beforeClosed()
      .pipe(filter(res => !!res));
  }
}
