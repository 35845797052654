import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {ProjectService} from '../service';
import {ProjectListResolver} from './project-list.resolver';

@Injectable()
export class ProjectListForCustomerResolver extends ProjectListResolver {
  constructor(router: Router, service: ProjectService) {
    super(router, service);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: customer} = route.params;
    return {...super.updateParameter(route), customer};
  }

  protected override mapStatus(status?: string): string | undefined {
    return status;
  }
}
