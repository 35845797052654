import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {AppAuthService, TimerecordService} from '../service';
import {mergeMap} from 'rxjs';
import {User} from '../../model';
import {filter, map, take} from 'rxjs/operators';
import {TimerecordListResolver, TimerecordSummaryResolver} from './timerecord-list-resolver.service';

@Injectable()
export class TimerecordListForUserResolver extends TimerecordListResolver {

  constructor(router: Router, service: TimerecordService, auth: AppAuthService) {
    super(router, {
      ...service,
      list: (queryParams: Params) => auth.user$.pipe(
        take(1),
        filter(user => !!user),
        map(user => user as unknown as User),
        mergeMap(user =>
          service.list({...queryParams, user: user.id}))
      )
    } as TimerecordService);
  }
}

@Injectable()
export class TimerecordSummaryForUserResolver extends TimerecordSummaryResolver {

  constructor(service: TimerecordService, auth: AppAuthService) {
    super({
      ...service,
      summary: (queryParams: Params) => auth.user$.pipe(
        take(1),
        filter(user => !!user),
        map(user => user as unknown as User),
        mergeMap(user => service.summary({...queryParams, user: user.id}))
      )
    } as TimerecordService);
  }
}
