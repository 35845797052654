import {Component, Inject} from '@angular/core';
import {Customer} from '../../model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AutocompleteMixin} from './autocomplete.mixin';

@Component({
  template: `
    <h1 mat-dialog-title>{{title}}</h1>
    <mat-dialog-content>
      <mat-form-field appearance="fill" class="col-2_3">
        <mat-label>Kunde wählen</mat-label>
        <input name="extern" matInput [formControl]="autocomplete.formControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayEntry"
                          (optionSelected)="entry=$event.option.value">
          <mat-option *ngFor="let entry of autocomplete.entries | async" [value]="entry">
            {{entry.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button mat-raised-button color="primary" [mat-dialog-close]="entry" [disabled]="!entry">
        <mat-icon>done</mat-icon>
        Auswählen
      </button>
    </mat-dialog-content>
  `
})
export class CustomerSelectDialogComponent {
  title: string;
  entry: Customer;
  autocomplete: AutocompleteMixin<Customer>;

  constructor(@Inject(MAT_DIALOG_DATA) {title, entries}: any) {
    this.title = title;
    this.autocomplete = new AutocompleteMixin(
      entries,
      (entry, search) =>
        entry.nr?.toLowerCase().includes(search) || entry.name?.toLowerCase().includes(search)
    );
  }

  displayEntry(entry: Customer): string {
    return entry ? (entry.nr as string + ' | ' + entry.name as string) : '';
  }
}
