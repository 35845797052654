import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {_DetailResolver} from './_detail.resolver';
import {ProjectService} from '../service';
import {Project, UUID} from '../../model';

@Injectable()
export class ProjectDetailResolver extends _DetailResolver<Project, UUID> {
  constructor(router: Router, toastr: ToastrService, service: ProjectService) {
    super(router, toastr, service, 'project');
  }
}
