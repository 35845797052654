import {Project} from '../../model';
import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from '../service';

@Injectable()
export class UserProjectResolver implements Resolve<Project[]> {
  constructor(private service: UserService) {
  }

  resolve = () => this.service.projectsForUser();
}
