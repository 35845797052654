import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {_Service} from './_service';
import {Invoice, UUID} from '../../model';
import {Observable} from 'rxjs';
import {Params} from '@angular/router';
import {buildQuery} from '../../util';

@Injectable()
export class InvoiceService extends _Service<Invoice, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/invoice');
  }

  downloadExport(params: Params): Observable<HttpResponse<Blob>> {
    return this.http.get(this.prefix + '/export' + buildQuery(params), {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
