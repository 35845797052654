import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProjectService} from '../service';
import {User} from '../../model';
import {catchError, take} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';

@Injectable()
export class ProjectUsersResolver implements Resolve<User[]> {
  constructor(private router: Router, private toastr: ToastrService, private service: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[]> {
    const id = route.params[`id`];
    return this.service.listUsers(id).pipe(
      take(1),
      catchError(err => {
        this.toastr.error(`${err.message}<br/>id=${id}`, 'Fehler beim Laden!',
          {disableTimeOut: true});
        this.router.navigate(['project']);
        return EMPTY;
      })
    );
  }
}
