<a mat-button style="float:right" [routerLink]="['/project', data.project]" [mat-dialog-close]="undefined">
  <mat-icon>launch</mat-icon>
  zum Projekt</a>
<h1 mat-dialog-title>{{title}}</h1>
<h5>{{subtitle}}</h5>

<mat-dialog-content>
  <form #form="ngForm" novalidate autocomplete="off" (keydown.enter)="$event.preventDefault()">

    <p>
      <mat-form-field appearance="fill" class="col-3_3">
        <mat-label>Beschreibung</mat-label>
        <input matInput name="description" [(ngModel)]="data.description" required [disabled]="!authService.isAdmin"
               #description="matInput">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Rechnungsnummer</mat-label>
        <input #nr="ngModel" matInput name="nr" [(ngModel)]="data.nr" required autotrim
               [app-duplicate-check]="['/api/invoice/check-number', data.id]" [disabled]="!authService.isAdmin">
        <mat-error *ngIf="nr.hasError('required')">Eingabe erforderlich</mat-error>
        <mat-error *ngIf="nr.hasError('duplicate')">Nummer bereits Vergeben</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Rechnungsdatum</mat-label>
        <input matInput type="date" name="dateInvoice" [(ngModel)]="data.dateInvoice" required
               [disabled]="!authService.isAdmin">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Zahlungsziel</mat-label>
        <input matInput type="date" name="dateZahlungsziel" [(ngModel)]="data.dateZahlungsziel"
               [disabled]="!authService.isAdmin">
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Zahlungseingang</mat-label>
        <input matInput type="date" name="datePayment" [(ngModel)]="data.datePayment"
               [disabled]="!authService.isAdmin">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Netto-Betrag</mat-label>
        <input [(ngModel)]="data.amount" matInput name="amount" type="number" appNumericInput required
               step="0.01" lang="de" [disabled]="!authService.isAdmin">
        <mat-error>Eingabe erforderlich</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>MwSt</mat-label>
        <input [(ngModel)]="data.mwst" matInput name="mwst" type="number" appNumericInput required
               step="0.01" lang="de" [disabled]="!authService.isAdmin">
        <mat-error>Eingabe erforderlich</mat-error>
        <button mat-button matSuffix mat-icon-button title="MwSt aus Netto berechnen" (click)="updateMwSt()"
                [disabled]="!authService.isAdmin">
          <mat-icon>lightbulb</mat-icon>
        </button>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Brutto-Betrag</mat-label>
        <input [value]="(data.amount+data.mwst) | number:'0.2-2'" matInput
               name="amountBrutto" class="number-input" readonly disabled>
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="undefined">Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="!form.dirty || form.invalid">
    Speichern
  </button>
</mat-dialog-actions>
