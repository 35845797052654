import {Injectable} from '@angular/core';
import {_ListAllResolver} from './_list-all.resolver';
import {CustomerTypeService} from '../service';
import {CustomerType, UUID} from '../../model';

@Injectable()
export class CustomerTypeAllResolver extends _ListAllResolver<CustomerType, UUID> {
  constructor(service: CustomerTypeService) {
    super(service);
  }
}
