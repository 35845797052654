import {NgModule} from '@angular/core';
import {CommonModule} from '../common.module';
import {UserListComponent} from './user-list.component';

@NgModule({
  declarations: [UserListComponent],
  imports: [CommonModule],
  exports: [UserListComponent],
})
export class UserListModule {
}
