import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {CustomerService} from '../service';
import {Customer, UUID} from '../../model';

@Injectable()
export class CustomerListResolver extends _ListResolver<Customer, UUID> {
  constructor(router: Router, service: CustomerService) {
    super(router, service, 'cPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {cPage, cSearch, cOrder} = route.queryParams;
    return {page: cPage, search: cSearch, order: cOrder};
  }
}
