import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {debounceTime, skip} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export abstract class BaseListComponent<TYPE, FILTER extends { [key: string]: any }> {
  searchDelay = new BehaviorSubject<any>(undefined);
  dataSource = new MatTableDataSource<TYPE>([]);
  filter: FILTER = {} as any;

  constructor(protected route: ActivatedRoute, public router: Router, private pageVar: string) {
    this.searchDelay
      .pipe(skip(1), debounceTime(500))
      .subscribe(() => this.search());

    this.route.queryParams.subscribe((params) => {
      this.filter = this.mapQueryParamsFilter(params);
    });
  }

  abstract get paginator(): MatPaginator;

  mapQueryParamsFilter(params: Params): FILTER {
    return {...(params || {})} as FILTER;
  }

  public updateFilter(): void {
    this.searchDelay.next(undefined);
  }

  public resetFilter(): void {
    this.filter = {} as any;
    this.search();
  }

  public search(): void {
    const queryParams: { [key: string]: any } = {...this.filter};
    queryParams[this.pageVar] = this.paginator.pageIndex;
    for (const key in queryParams) {
      if (queryParams[key] === '' || queryParams[key] === 0 || queryParams[key] === false) {
        queryParams[key] = undefined;
      }
    }
    this.router.navigate([], {relativeTo: this.route, queryParams});
  }
}
