import {Injectable} from '@angular/core';
import {Project, UUID} from '../../model';
import {MatDialog} from '@angular/material/dialog';
import {ProjectService} from '../service';
import {ProjectSelectDialogComponent} from './project-select-dialog.component';
import {_SelectDialog} from './_select-dialog.service';

@Injectable()
export class ProjectSelectDialog extends _SelectDialog<Project, UUID> {
  constructor(matDialog: MatDialog, service: ProjectService) {
    super(matDialog, service, ProjectSelectDialogComponent);
  }
}
