import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {_DetailResolver} from './_detail.resolver';
import {TimerecordService} from '../service';
import {Timerecord, UUID} from '../../model';

@Injectable()
export class TimerecordDetailResolver extends _DetailResolver<Timerecord, UUID> {
  constructor(router: Router, toastr: ToastrService, service: TimerecordService) {
    super(router, toastr, service, 'timerecord');
  }
}
