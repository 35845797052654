import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Contact, Extern} from '../../model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NgForm, NgModel, UntypedFormControl} from '@angular/forms';
import {Observable, startWith} from 'rxjs';
import {map} from 'rxjs/operators';

type ExternContactDetailDialogData = {
  extern: Extern[],
};

@Component({
  selector: 'app-contact-select-dialog',
  templateUrl: 'extern-contact-select-dialog.component.html',
  styleUrls: ['extern-contact-select-dialog.component.scss']
})
export class ExternContactSelectDialogComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  @ViewChildren('detail') detailTitles: QueryList<NgModel>;
  extern: Extern = {contacts: []} as any;
  contact?: Contact;
  externFormControl = new UntypedFormControl();
  filteredExtern: Observable<Extern[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExternContactDetailDialogData) {
  }

  ngOnInit(): void {
    this.filteredExtern = this.externFormControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => {
        if (name) {
          const filterValue = name.toLowerCase();
          return this.data.extern.filter(option => option.name?.toLowerCase().includes(filterValue));
        } else {
          return this.data.extern.slice();
        }
      }),
    );
  }

  displayExtern(extern: Extern): string {
    return extern ? (extern.name + '') : '';
  }

  selectExtern(extern: Extern): void {
    this.extern = extern;
    this.contact = (this.extern.contacts.length > 0) ? this.extern.contacts[0] : undefined;
  }
}
