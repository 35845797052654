import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service, url} from './_service';
import {HasId, Note, UUID} from '../../model';

@Injectable()
export class NoteService extends _Service<Note, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/note');
  }

  createNote = (parent: HasId<UUID>, data: Note) =>
    this.http.post<Note[]>(url(this.prefix, this.mapId(parent.id)), {...data})

  listAllNotes = (parent: HasId<UUID>) =>
    this.http.get<Note[]>(url(this.prefix, this.mapId(parent.id)))
}
