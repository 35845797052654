import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ProjectService} from '../service';
import {Project} from '../../model';
import {Observable} from 'rxjs';

@Injectable()
export class ProjectDefaultResolver implements Resolve<Project> {

  constructor(private service: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    return this.service.detail('default');
  }
}
