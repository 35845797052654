import {Injectable} from '@angular/core';
import {ProjectService} from '../service';
import {_ByQueryResolver} from './_by-query.resolver';
import {Project, UUID} from '../../model';

@Injectable()
export class ProjectByTQueryResolver extends _ByQueryResolver<Project, UUID> {
  constructor(service: ProjectService) {
    super(service, 'tProject');
  }
}
