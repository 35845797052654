<h1 mat-dialog-title>Löschen bestätigen</h1>

<mat-dialog-content>
  Möchten Sie {{data.type}} <strong>{{data.name}}</strong> wirklich löschen ?
</mat-dialog-content>

<mat-dialog-actions>
  <div class="spacer"></div>
  <button mat-raised-button [mat-dialog-close]="false">Nein</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true">Ja</button>
</mat-dialog-actions>
