/* tslint:disable:directive-selector */
import {Directive, HostListener, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({selector: '[autotrim]'})
export class AutotrimDirective implements OnInit {

  constructor(private model: NgModel) {
  }

  @HostListener('change')
  @HostListener('focus')
  @HostListener('blur')
  ngOnInit(): void {
    if (this.model.value != null) {
      const newValue = this.model.value.trim();
      if (this.model.value !== newValue) {
        this.model.control.patchValue(newValue);
      }
    }
  }
}
