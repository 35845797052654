import {Component, OnInit} from '@angular/core';
import {AppAuthService} from './app-auth.service';

@Component({template: ''})
export class LogoutComponent implements OnInit {

  constructor(private authService: AppAuthService) {
  }

  ngOnInit(): void {
    this.authService.logout().subscribe(() => location.href = '/');
  }
}
