import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Holiday} from '../../model';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {HolidayDetailDialogComponent} from './holiday-detail-dialog.component';

@Injectable()
export class HolidayDetailDialog {

  constructor(private matDialog: MatDialog) {
  }

  open(title: string, holiday: Holiday): Observable<Holiday> {
    return this.matDialog.open(HolidayDetailDialogComponent,
      {
        width: '750px', disableClose: false,
        data: {title, holiday: {...holiday}}
      })
      .beforeClosed().pipe(filter(res => !!res));
  }
}
