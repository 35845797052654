import {ChangeDetectorRef, Component, Inject, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Contact} from '../../model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm, NgModel} from '@angular/forms';

export class DetailType {
  suggestions: string[];
  model?: NgModel;

  constructor(private options: string[], public name?: string, public value?: string) {
    this.suggestions = this.options;
  }

  public updateSuggestions(): void {
    const searchString = (this.name || '').toLowerCase();
    this.suggestions = this.options.filter(option => option.toLowerCase().includes(searchString));
  }
}

@Component({templateUrl: 'contact-detail-dialog.component.html', styleUrls: ['contact-detail-dialog.component.scss']})
export class ContactDetailDialogComponent {
  contact: Contact;
  details: DetailType[];
  options: string[];
  actitleOptions: string[];
  actitleSuggestions: string[] = [];
  roleOptions: string[];
  roleSuggestions: string[] = [];
  @ViewChild('form') form: NgForm;
  @ViewChildren('detail') detailTitles: QueryList<NgModel>;
  doScroll = false;
  title: string;
  subtitle: string;

  constructor(public cdRef: ChangeDetectorRef,
              private dialogRef: MatDialogRef<ContactDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) {title, subtitle, contact, autocomplete}: any) {
    this.contact = contact;
    this.options = autocomplete.options;
    this.actitleOptions = autocomplete.actitle;
    this.roleOptions = autocomplete.role;
    const details = contact.detail || {};
    this.details = Object.keys(details).map(name => new DetailType(this.options, name, details[name]));
    this.title = title;
    this.subtitle = subtitle;
  }

  addDetail(): void {
    this.doScroll = true;
    this.details.push(new DetailType(this.options));
  }

  updateActitleSuggestions(): void {
    const searchString = (this.contact.actitle || '').toLowerCase();
    this.actitleSuggestions = this.actitleOptions.filter(option => option.toLowerCase().includes(searchString));
  }

  updateRoleSuggestions(): void {
    const searchString = (this.contact.role || '').toLowerCase();
    this.roleSuggestions = this.roleOptions.filter(option => option.toLowerCase().includes(searchString));
  }

  checkDuplicates(): void {
    this.detailTitles.forEach(ngModel => {
      const control = ngModel.control;
      if (control.hasError('required')) {
        return;
      } else if (ngModel.value && this.details.filter(({name}) => name === ngModel.value).length > 1) {
        control.setErrors({duplicate: true});
      } else if (control.getError('duplicate')) {
        control.setErrors(null);
      }
    });
  }

  onClose(): void {
    const detail = this.details.reduce((prev, cur) => {
      if (!!cur.name && !!cur.value?.trim()) {
        prev[cur.name] = cur.value.trim();
      }
      return prev;
    }, {} as { [key: string]: string });

    this.dialogRef.close({...this.contact, detail});
  }

  removeItem(pos: number): void {
    this.details.splice(pos, 1);
    this.form.control.markAsDirty();
  }
}
