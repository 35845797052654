<h1 mat-dialog-title>
  Berater / Kontakt einfügen:
</h1>

<mat-dialog-content>
  <mat-form-field appearance="fill" class="col-2_3" *ngIf="data.extern.length>0">
    <mat-label>Bestehenden kontakt wählen</mat-label>
    <input name="extern" matInput [formControl]="externFormControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayExtern"
                      (optionSelected)="selectExtern($event.option.value)">
      <mat-option *ngFor="let option of filteredExtern | async" [value]="option">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="col-2_3" *ngIf="data.extern.length>0">
    <mat-label>Bestehenden kontakt wählen</mat-label>
    <mat-select name="title" [(ngModel)]="contact">
      <mat-option [value]="u" *ngFor="let u of extern.contacts">
        {{u.name}}{{u.firstname ? ", " : ""}}{{u.firstname}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" [mat-dialog-close]="contact" [disabled]="!contact">
    <mat-icon>done</mat-icon>
    Hinzufügen
  </button>
  <h4>oder</h4>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [routerLink]="['extern','create']" [mat-dialog-close]="undefined">
    <mat-icon>launch</mat-icon>
    Neuen Berater Anlegen
  </button>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="undefined">Abbrechen</button>
</mat-dialog-actions>


