import {Injectable} from '@angular/core';
import {UserService} from '../service';
import {User} from '../../model';
import {Resolve} from '@angular/router';

@Injectable()
export class UserAllManagerResolver implements Resolve<User[]> {

  constructor(private service: UserService) {
  }

  resolve = () => this.service.listAllManager();
}
