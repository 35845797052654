import {Resolve} from '@angular/router';
import {_Service} from '../service/_service';
import {HasId} from '../../model';

// tslint:disable:class-name
export class _ListAllResolver<T extends HasId<I>, I> implements Resolve<T[]> {
  constructor(private service: _Service<T, I>) {
  }

  resolve = () => this.service.listAll();
}
