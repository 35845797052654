import {Component} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({selector: 'app-root', templateUrl: 'app.component.html', styleUrls: ['app.component.scss']})
export class AppComponent {
  constructor(public loader: LoadingBarService, public route: Router) {
    const loaderState = loader.useRef('router');
    route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        loaderState.stop();
      }
    });
  }
}
