import {Component, ViewChild} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {Router} from '@angular/router';
import {NgModel} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({templateUrl: './login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent {
  @ViewChild('username', {static: true}) username: NgModel;
  data = {login: '', password: ''};
  lastError?: string;

  constructor(private router: Router, private authService: AppAuthService) {
  }

  login(): void {
    this.lastError = undefined;
    this.authService.login(this.data.login, this.data.password)
      .pipe(catchError(err => {
        if (err.error === 'Bad credentials') {
          this.lastError = 'Ungültige Zugangsdaten';
          return of();
        }
        throw err;
      }))
      .subscribe(() => this.router.navigate(['/']));
  }
}
