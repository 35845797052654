import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Resolve, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {TimerecordService} from '../service';
import {Timerecord, UUID} from '../../model';
import {Observable} from 'rxjs';

@Injectable()
export class TimerecordListResolver extends _ListResolver<Timerecord, UUID> {
  constructor(router: Router, service: TimerecordService) {
    super(router, service, 'tPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {tPage, tUser, tCustomer, tProject, tStart, tFinish} = route.queryParams;
    return {page: tPage, user: tUser, customer: tCustomer, project: tProject, start: tStart, finish: tFinish};
  }
}

@Injectable()
export class TimerecordSummaryResolver implements Resolve<Timerecord> {

  constructor(private service: TimerecordService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Timerecord> {
    return this.service.summary(this.updateParameter(route));
  }

  updateParameter(route: ActivatedRouteSnapshot): Params {
    const {tUser, tCustomer, tProject, tStart, tFinish} = route.queryParams;
    return {user: tUser, customer: tCustomer, project: tProject, start: tStart, finish: tFinish};
  }
}
