import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {_Service, url} from './_service';
import {Contact, Project, User, UUID} from '../../model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Params} from '@angular/router';
import {buildQuery} from '../../util';

@Injectable()
export class ProjectService extends _Service<Project, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/project');
  }

  addManager(project: Project, user: User): Observable<any> {
    return this.http.put<any>(`${this.prefix}/${project.id}/manager/${user.id}`, {});
  }

  removeManager(project: Project, user: User): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${project.id}/manager/${user.id}`);
  }

  listUsers(project: Project): Observable<User[]> {
    return of([]);
  }

  addUser(project: Project, user: User): Observable<any> {
    return this.http.put<any>(`${this.prefix}/${project.id}/user/${user.id}`, {});
  }

  removeUser(project: Project, user: User): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${project.id}/user/${user.id}`);
  }

  addContact(project: Project, contact: Contact): Observable<any> {
    return this.http.put<any>(`${this.prefix}/${project.id}/contact/${contact.id}`, {});
  }

  removeContact(project: Project, contact: Contact): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${project.id}/contact/${contact.id}`);
  }

  addExternContact(project: Project, contact: Contact): Observable<any> {
    return this.http.put<any>(`${this.prefix}/${project.id}/extern/${contact.id}`, {});
  }

  removeExternContact(project: Project, contact: Contact): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${project.id}/extern/${contact.id}`);
  }

  downloadPdfOverview(project: Project): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.prefix}/${project.id}/pdf-overview`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadPdfTimes(project: Project, params: Params): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.prefix}/${project.id}/pdf-times${buildQuery(params)}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  nextProjectNumber = () => this.http.get<{ next: string }>(url(this.prefix, 'next-number'))
    .pipe(map(({next}) => next))

  listAllTags = () => this.http.get<string[]>(url(this.prefix, 'tags'));

  isManager = (projectId: string) => this.http.get<{ manager: boolean }>(url(this.prefix, projectId, 'manager'));

  downloadExport(params: Params): Observable<HttpResponse<Blob>> {
    return this.http.get(this.prefix + '/export' + buildQuery(params), {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
