import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {InvoiceService} from '../service';
import {Invoice, UUID} from '../../model';

@Injectable()
export class InvoiceListResolver extends _ListResolver<Invoice, UUID> {
  constructor(router: Router, service: InvoiceService) {
    super(router, service, 'iPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {iPage, iStatus, iCustomer, iStart, iFinish, iSearch, iProject, iManager, iOrder} = route.queryParams;
    return {
      page: iPage, status: this.mapStatus(iStatus), customer: iCustomer, start: iStart, finish: iFinish,
      search: iSearch, project: iProject, manager: iManager, order: iOrder
    };
  }

  protected mapStatus(status?: string): string | undefined {
    switch (status) {
      case 'OPEN':
        return undefined;
      default:
        return status;
    }
  }
}

