import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {NoteService} from '../service';
import {Note} from '../../model';
import {Observable} from 'rxjs';

@Injectable()
export class NoteResolver implements Resolve<Note[]> {
  constructor(private service: NoteService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Note[]> {
    const id = route.params[`id`];
    return this.service.listAllNotes({id});
  }
}
