import {Component, Inject, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Contact, Customer, Project} from '../../model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm, NgModel} from '@angular/forms';
import {mergeMap} from 'rxjs/operators';
import {ContactDetailDialog} from './contact-detail-dialog.service';
import {CustomerService} from '../service';
import {ToastrService} from 'ngx-toastr';

type ContactDetailDialogData = {
  contacts: Contact[],
  project: Project,
  customer: Customer
};

@Component({
  selector: 'app-contact-select-dialog',
  templateUrl: 'contact-select-dialog.component.html',
  styleUrls: ['contact-select-dialog.component.scss']
})
export class ContactSelectDialogComponent {
  @ViewChild('form') form: NgForm;
  @ViewChildren('detail') detailTitles: QueryList<NgModel>;
  contact: Contact;

  constructor(private customerService: CustomerService, private dialogRef: MatDialogRef<ContactSelectDialogComponent>,
              private detailDialog: ContactDetailDialog, private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: ContactDetailDialogData) {
  }

  createContact(): void {
    this.detailDialog.editContact('Kontakt Erstellen', '', {} as Contact)
      .pipe(
        mergeMap(newContact => this.customerService.addContact(this.data.customer, newContact))
      )
      .subscribe((contact) => {
        this.toastr.success('Erfolg!', 'Kontakt wurde erstellt');
        this.dialogRef.close({...contact});
      });
  }

  selectContact(): void {
    this.dialogRef.close({...this.contact});
  }
}
