export function buildQuery(query: { [key: string]: any }): string {
  const keys = query ? Object.keys(query).filter(key => query[key] != null) : [];

  if (keys.length > 0) {
    return '?' + keys.map(key => key + '=' + encodeURIComponent(query[key])).join('&');
  }
  return '';
}

export const weekDays: { [key: string]: string } = {
  MONDAY: 'Mo',
  TUESDAY: 'Di',
  WEDNESDAY: 'Mi',
  THURSDAY: 'Do',
  FRIDAY: 'Fr',
  SUNDAY: 'Sa',
  SATURDAY: 'So'
};
