import {ActivatedRouteSnapshot, Params, Resolve, Router} from '@angular/router';
import {_Service} from '../service/_service';
import {HasId, Page} from '../../model';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

// tslint:disable:class-name
export class _ListResolver<T extends HasId<I>, I> implements Resolve<Page<T>> {
  constructor(private router: Router, private service: _Service<T, I>, private pageVar: string) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Page<T>> {
    return this.service
      .list(this.updateParameter(route))
      .pipe(tap(result => {
        console.log('check page ', result.pageNumber, '>=', result.totalPages);
        if (result.pageNumber >= result.totalPages && result.pageNumber > 0) {
          const queryParams = {...route.queryParams};
          queryParams[this.pageVar] = Math.max(result.totalPages - 1, 0) || undefined;
          this.router.navigate([], {queryParams});
        }
      }));
  }

  updateParameter(route: ActivatedRouteSnapshot): Params {
    return {...route.queryParams};
  }
}
