import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({selector: '[appNumericInput]'})
export class NumericInputDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('decimals') decimals = 2;

  constructor(private model: NgModel, private el: ElementRef) {
  }

  ngOnInit(): void {
    setTimeout(() => this.update());
  }

  @HostListener('change')
  @HostListener('blur')
  @HostListener('paste')
  update(): void {
    try {
      const parsedValue = parseFloat(this.model.value);
      if (Number.isNaN(parsedValue)) {
        this.model.control.patchValue(null);
      } else {
        const newValueStr = parsedValue.toFixed(this.decimals);
        const newValueNum = parseFloat(newValueStr);
        if (this.model.value !== newValueNum || this.el.nativeElement.value !== newValueStr) {
          this.model.control.patchValue(newValueNum);
          this.el.nativeElement.value = newValueStr;
        }
      }
    } catch (ignored) {
    }
  }
}
