import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '../common.module';
import {AppAuthService} from './app-auth.service';
import {AuthGuard} from './auth-guard.service';
import {LoginComponent} from './login.component';
import {LogoutComponent} from './logout.component';

@NgModule({
  declarations: [LoginComponent, LogoutComponent],
  imports: [CommonModule,
    RouterModule.forChild([
      {path: 'login', component: LoginComponent},
      {path: 'logout', component: LogoutComponent},
    ])],
  providers: [AuthGuard, AppAuthService],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
