import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service, url} from './_service';
import {Project, User, UUID} from '../../model';

@Injectable()
export class UserService extends _Service<User, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/user');
  }

  password = (currentPassword: string, newPassword: string) =>
    this.http.put<User>('api/auth/password', {currentPassword, newPassword})

  listAllManager = () => this.http.get<User[]>(url(this.prefix, 'all-manager'));

  projectsForUser = () => this.http.get<Project[]>(url(this.prefix, 'projects'));
}
