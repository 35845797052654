import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Invoice} from '../../model';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {InvoiceDetailDialogComponent} from './invoice-detail-dialog.component';

@Injectable()
export class InvoiceDetailDialog {

  constructor(private matDialog: MatDialog) {
  }

  open(title: string, subtitle: string, invoice: Invoice): Observable<Invoice> {
    return this.matDialog.open(InvoiceDetailDialogComponent,
      {
        width: '750px', disableClose: false,
        data: {title, subtitle, invoice: {...invoice}}
      })
      .beforeClosed().pipe(filter(res => !!res));
  }
}
