import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {url} from './_service';
import {AutocompleteOptions, Contact, HasId, UUID} from '../../model';

@Injectable()
export class ContactService {
  private prefix = 'api/contact';

  constructor(protected http: HttpClient) {
  }

  mapId = (f: UUID): string => (f as any);

  update = (data: Contact) =>
    this.http.put<Contact>(url(this.prefix, this.mapId(data.id)), {...data})

  listAllContacts = (parent: HasId<UUID>) =>
    this.http.get<Contact[]>(url(this.prefix, 'forParent', this.mapId(parent.id)))

  listAutocompleteOptions = () =>
    this.http.get<AutocompleteOptions>(url(this.prefix, 'options'))

  setPrimay = (parent: HasId<UUID>, data: Contact) =>
    this.http.post<Contact>(url(this.prefix, 'primary', this.mapId(parent.id), this.mapId(data.id)), {})
}
