import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {AppAuthService, TimerecordService} from '../service';
import {TimerecordListResolver, TimerecordSummaryResolver} from './timerecord-list-resolver.service';
import {mergeMap, of} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class TimerecordListForCustomerResolver extends TimerecordListResolver {
  constructor(router: Router, service: TimerecordService, auth: AppAuthService) {
    super(router, {
      ...service,
      list: (queryParams: Params) => auth.isAdmin$.pipe(
        take(1),
        mergeMap((admin) => admin ? service.list(queryParams) : of({content: []} as any))
      )
    } as TimerecordService);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: customer} = route.params;
    return {...super.updateParameter(route), customer};
  }
}

@Injectable()
export class TimerecordSummaryForCustomerResolver extends TimerecordSummaryResolver {

  constructor(service: TimerecordService, auth: AppAuthService) {
    super({
      ...service,
      summary: (queryParams: Params) => auth.isAdmin$.pipe(
        take(1),
        mergeMap((admin) => admin ? service.summary(queryParams) : of({} as any))
      )
    } as TimerecordService);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: customer} = route.params;
    return {...super.updateParameter(route), customer};
  }
}
