import {Injectable} from '@angular/core';
import {Contact, Customer, Project} from '../../model';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ContactSelectDialogComponent} from './contact-select-dialog.component';

@Injectable()
export class ContactSelectDialog {

  constructor(private matDialog: MatDialog) {
  }

  open(customer: Customer, project: Project, contacts: Contact[]): Observable<Contact> {
    return this.matDialog.open(ContactSelectDialogComponent,
      {
        width: '750px', disableClose: false,
        data: {customer, project, contacts}
      })
      .beforeClosed()
      .pipe(filter(res => !!res));
  }
}
