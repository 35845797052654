import {Injectable} from '@angular/core';
import {Customer, UUID} from '../../model';
import {MatDialog} from '@angular/material/dialog';
import {CustomerService} from '../service';
import {CustomerSelectDialogComponent} from './customer-select-dialog.component';
import {_SelectDialog} from './_select-dialog.service';

@Injectable()
export class CustomerSelectDialog extends _SelectDialog<Customer, UUID> {
  constructor(matDialog: MatDialog, service: CustomerService) {
    super(matDialog, service, CustomerSelectDialogComponent);
  }
}
