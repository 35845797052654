import {HttpClient} from '@angular/common/http';
import {Directive, forwardRef, Input} from '@angular/core';
import {NG_ASYNC_VALIDATORS, UntypedFormControl, ValidationErrors, Validator} from '@angular/forms';

import {of} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {buildQuery} from '../util';

@Directive({
  selector: '[app-duplicate-check][ngModel]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: forwardRef(() => DuplicateCheckValidatorDirective),
    multi: true
  }]
})
export class DuplicateCheckValidatorDirective implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('app-duplicate-check') api: string[];

  constructor(private http: HttpClient) {
  }

  validate(control: UntypedFormControl): ValidationErrors {
    if (!control.value) {
      return of(null);
    }
    const uri = this.api.filter(i => !!i).join('/');
    return this.http.get<any>(uri + buildQuery({q: control.value}))
      .pipe(map(r => r.duplicate ? {duplicate: true} : null), first());
  }
}
