import {Injectable} from '@angular/core';
import {UserService} from '../service';
import {_ByQueryResolver} from './_by-query.resolver';
import {User, UUID} from '../../model';

@Injectable()
export class UserManagerByIQueryResolver extends _ByQueryResolver<User, UUID> {
  constructor(service: UserService) {
    super(service, 'iManager');
  }
}
