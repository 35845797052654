import {Injectable} from '@angular/core';
import {_ListAllResolver} from './_list-all.resolver';
import {CustomerService} from '../service';
import {Customer, UUID} from '../../model';

@Injectable()
export class CustomerAllResolver extends _ListAllResolver<Customer, UUID> {
  constructor(service: CustomerService) {
    super(service);
  }
}
