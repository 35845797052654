import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {ExternService} from '../service';
import {Extern, UUID} from '../../model';

@Injectable()
export class ExternListResolver extends _ListResolver<Extern, UUID> {
  constructor(router: Router, service: ExternService) {
    super(router, service, 'ePage');
  }
}
