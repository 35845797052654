import {MatPaginatorIntl} from '@angular/material/paginator';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';

@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
  constructor(@Inject(LOCALE_ID) localeId: string) {
    super();
    this.nextPageLabel = 'nächste Seite';
    this.previousPageLabel = 'vorherige Seite';
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }
}
