import {Injectable} from '@angular/core';
import {CustomerService} from '../service';
import {Customer} from '../../model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class CustomerByProjectResolver implements Resolve<Customer> {
  constructor(private service: CustomerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    const {id} = route.params;
    return this.service.byProject(id);
  }
}
