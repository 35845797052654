import {Injectable} from '@angular/core';
import {ProjectService} from '../service';
import {Resolve} from '@angular/router';

@Injectable()
export class TagsResolver implements Resolve<string[]> {
  constructor(private service: ProjectService) {
  }

  resolve = () => this.service.listAllTags();
}
