import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {Invoice} from '../../model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {AppAuthService} from '../auth';
import {MatInput} from '@angular/material/input';

@Component({templateUrl: 'invoice-detail-dialog.component.html', styleUrls: ['invoice-detail-dialog.component.scss']})
export class InvoiceDetailDialogComponent {
  @ViewChild('form') form: NgForm;
  @ViewChild('description') description: MatInput;
  title: string;
  subtitle: string;
  data: Invoice;

  constructor(public cdRef: ChangeDetectorRef, public authService: AppAuthService,
              private dialogRef: MatDialogRef<InvoiceDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) {title, subtitle, invoice}: any) {
    this.data = invoice;
    this.title = title;
    this.subtitle = subtitle;
    this.dialogRef.afterOpened().subscribe(() => setTimeout(() => this.description.focus()));
  }

  updateMwSt(): void {
    this.data.mwst = Math.round(this.data.amount * 19) / 100;
  }
}
