import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {UserService} from '../service';
import {User, UUID} from '../../model';

@Injectable()
export class UserListResolver extends _ListResolver<User, UUID> {
  constructor(router: Router, service: UserService) {
    super(router, service, 'uPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {uPage, uSearch} = route.queryParams;
    return {page: uPage, search: uSearch};
  }
}
