<form #form="ngForm" class="form">
  <mat-card>
    <mat-card-content>
      <div class="flex-container">
        <div class="flex-col3 form">
          <mat-error *ngIf="lastError">
            <h4>{{lastError}}</h4>
          </mat-error>
        </div>
      </div>

      <div class="flex-container">
        <div class="flex-col3 form">
          <h4>Login</h4>

          <mat-form-field>
            <input #name="ngModel" [(ngModel)]="data.login" autocomplete="new-password" autofocus matInput name="login"
                   placeholder="Login" required>
            <mat-error *ngIf="name.hasError('required')">
              Required field
            </mat-error>
            <mat-error *ngIf="form.hasError('invalidLogin')">
              Login invalid
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input #password="ngModel" [(ngModel)]="data.password" autocomplete="new-password" matInput name="password"
                   placeholder="Passwort" required type="password">
            <mat-error *ngIf="password.hasError('required')">
              Field required
            </mat-error>
          </mat-form-field>

          <mat-action-row>
            <button (click)="login()" [disabled]="form.invalid" color="primary" mat-button mat-raised-button>
              Login
            </button>
          </mat-action-row>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
