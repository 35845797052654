import {NgModule} from '@angular/core';
import {ContactDetailDialogComponent} from './contact-detail-dialog.component';
import {ContactListComponent} from './contact-list.component';
import {CommonModule} from '../index';
import {ExternListComponent} from '../extern-list/extern-list.component';

@NgModule({
  declarations: [ContactListComponent, ContactDetailDialogComponent, ExternListComponent],
  imports: [CommonModule],
  exports: [ContactListComponent, ContactDetailDialogComponent, ExternListComponent],
  bootstrap: [ContactDetailDialogComponent]
})
export class ContactModule {
}
