import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [{
  path: '', pathMatch: 'full', redirectTo: '/project'
}, {
  path: 'user',
  loadChildren: () => import('./user/user.routing.module').then(mod => mod.UserRoutingModule)
}, {
  path: 'customer',
  loadChildren: () => import('./customer/customer-routing.module').then(mod => mod.CustomerRoutingModule)
}, {
  path: 'project',
  loadChildren: () => import('./project/project-routing.module').then(mod => mod.ProjectRoutingModule)
}, {
  path: 'timerecord',
  loadChildren: () => import('./timerecord/timerecord-routing.module').then(mod => mod.TimerecordRoutingModule)
}, {
  path: 'extern',
  loadChildren: () => import('./extern/extern-routing.module').then(mod => mod.ExternRoutingModule)
}, {
  path: 'invoice',
  loadChildren: () => import('./invoice/invoice-routing.module').then(mod => mod.InvoiceRoutingModule)
}, {
  path: 'holiday',
  loadChildren: () => import('./holiday/holiday-routing.module').then(mod => mod.HolidayRoutingModule)
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
