import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export class ConfirmDeleteDialogData {
  type: string;
  name: string;
}

@Component({templateUrl: 'confirm-delete-dialog.component.html'})
export class ConfirmDeleteDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteDialogData) {
  }
}
