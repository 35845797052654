import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {_DetailResolver} from './_detail.resolver';
import {ExternService} from '../service';
import {Extern, UUID} from '../../model';

@Injectable()
export class ExternDetailResolver extends _DetailResolver<Extern, UUID> {
  constructor(router: Router, toastr: ToastrService, service: ExternService) {
    super(router, toastr, service, 'extern');
  }
}
