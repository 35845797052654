import {Injectable} from '@angular/core';
import {_ListAllResolver} from './_list-all.resolver';
import {ProjectService} from '../service';
import {Project, UUID} from '../../model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ProjectAllActiveResolver extends _ListAllResolver<Project, UUID> {
  constructor(http: HttpClient, service: ProjectService) {
    super({
      ...service,
      listAll: () => service.listAll({status: 'INPROCESS'})
    } as ProjectService);
  }
}
