import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ConfirmDeleteDialogComponent, ConfirmDeleteDialogData} from './confirm-delete-dialog.component';

@Injectable()
export class ConfirmDeleteDialog {
  constructor(private dialog: MatDialog) {
  }

  open(data: ConfirmDeleteDialogData): Observable<MatDialogRef<ConfirmDeleteDialogComponent>> {
    return this.dialog.open(ConfirmDeleteDialogComponent, {width: '500px', data})
      .afterClosed()
      .pipe(filter(res => !!res));
  }
}
