import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service} from './_service';
import {CustomerType, UUID} from '../../model';

@Injectable()
export class CustomerTypeService extends _Service<CustomerType, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/customer-type');
  }
}
