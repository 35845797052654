import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {AppAuthService, ProjectService, TimerecordService} from '../service';
import {TimerecordListResolver, TimerecordSummaryResolver} from './timerecord-list-resolver.service';
import {mergeMap, of} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class TimerecordListForProjectResolver extends TimerecordListResolver {
  constructor(router: Router, service: TimerecordService, auth: AppAuthService, projectService: ProjectService) {
    super(router, {
      ...service,
      list: (queryParams: Params) => {
        const {project} = queryParams;
        return auth.isAdmin$.pipe(
          take(1),
          mergeMap((admin) => admin
            ? service.list(queryParams)
            : projectService.isManager(project).pipe(
              take(1),
              mergeMap(({manager}) => manager
                ? service.list(queryParams)
                : of({content: []} as any)
              ))
          ));
      }
    } as TimerecordService);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: project} = route.params;
    return {...super.updateParameter(route), project};
  }
}

@Injectable()
export class TimerecordSummaryForProjectResolver extends TimerecordSummaryResolver {

  constructor(service: TimerecordService, auth: AppAuthService, projectService: ProjectService) {
    super({
      ...service,
      summary: (queryParams: Params) => {
        const {project} = queryParams;
        return auth.isAdmin$.pipe(
          take(1),
          mergeMap((admin) => admin
            ? service.summary(queryParams)
            : projectService.isManager(project).pipe(
              take(1),
              mergeMap(({manager}) => manager
                ? service.summary(queryParams)
                : of({} as any)
              ))
          ));
      }
    } as TimerecordService);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: project} = route.params;
    return {...super.updateParameter(route), project};
  }
}
