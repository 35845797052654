import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service} from './_service';
import {Holiday} from '../../model';

@Injectable()
export class HolidayService extends _Service<Holiday, Date> {

  constructor(http: HttpClient) {
    super(http, 'api/holiday');
  }

  override mapId = (f: Date): string => new Date(f).toISOString().split('T', 2)[0];
}
