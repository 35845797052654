import {Injectable} from '@angular/core';
import {Observable, ObservableInput, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';

const jwt = new JwtHelperService();

export class AuthUser {
  login: string;
  id: string;
  token: string;
  roles: string[];

  constructor(res: AuthUser) {
    this.token = res.token;
    try {
      const decodeToken = jwt.decodeToken(this.token);
      this.login = decodeToken.sub;
      this.roles = decodeToken.a;
      this.id = decodeToken.jti;
    } catch (e) {
      console.error('failed decoding token:', e);
      throw e;
    }
  }
}

@Injectable()
export class AppAuthService {
  user?: AuthUser;
  isAdmin: boolean;

  user$: Subject<AuthUser | undefined> = new ReplaySubject<AuthUser | undefined>(1);
  isAdmin$: Subject<boolean> = new ReplaySubject<boolean>(1);

  private http: HttpClient;

  constructor(backend: HttpXhrBackend) {
    this.http = new HttpClient(backend);

    this.user$.subscribe(user => {
      if (user) {
        localStorage.setItem('auth-token', user.token);
      } else {
        localStorage.removeItem('auth-token');
      }
      this.user = user;
      this.isAdmin = user && user.roles.indexOf('ADMIN') > -1 || false;
      this.isAdmin$.next(this.isAdmin);
    });

    const token = localStorage.getItem('auth-token');
    if (token) {
      this.check(token).subscribe();
    } else {
      this.user$.next(undefined);
    }
  }

  get isLoggedIn(): AuthUser | undefined {
    return this.user;
  }

  check(token: string): Observable<AuthUser | null> {
    return this.http
      .get<AuthUser>('api/auth/refresh',
        {headers: new HttpHeaders({Authorization: 'Bearer ' + token})})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError(err => {
          if (err.status !== 401 && err.status !== 403) {
            throw err;
          }
          this.user$.next(undefined);
          return of(null);
        })
        // shareReplay(1, 60000)
      );
  }

  login(username: string, password: string): Observable<AuthUser> {
    localStorage.removeItem('auth-token');
    return this.http
      .post<AuthUser>('api/auth/login', {username, password})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err): ObservableInput<any> => {
          this.user$.next(undefined);
          throw err;
        })
      );
  }

  logout(): Observable<any> {
    localStorage.removeItem('auth-token');
    return of(null).pipe(
      tap(() => this.user$.next(undefined))
    );
  }
}
