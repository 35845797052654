import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service, url} from './_service';
import {Contact, Customer, UUID} from '../../model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class CustomerService extends _Service<Customer, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/customer');
  }

  byProject = (id: UUID) => this.http.get<Customer>(url(this.prefix, 'by-project', id));

  nextCustomerNumber = () => this.http.get<{ next: string }>(url(this.prefix, 'next-number'))
    .pipe(map(({next}) => next))

  addContact(customer: Customer, contact: Contact): Observable<any> {
    return this.http.post<any>(`${this.prefix}/${customer.id}/contact`, {...contact});
  }

  removeContact(customer: Customer, contact: Contact): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${customer.id}/contact/${contact.id}`);
  }
}
