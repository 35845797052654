import {CanDeactivate} from '@angular/router';
import {Observable, of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AfterViewInit, Component, ElementRef, Injectable} from '@angular/core';

export interface FormComponent {
  hasUnsavedChanges(): boolean;
}

@Injectable()
export class PreventUnsavedChangesGuard implements CanDeactivate<FormComponent> {
  constructor(private dialog: MatDialog) {
  }

  canDeactivate(component: FormComponent): Observable<boolean> {
    return component.hasUnsavedChanges && component.hasUnsavedChanges()
      ? this.dialog.open(HasUnsavedChangesDialogComponent, {width: '500px'}).afterClosed()
      : of(true);
  }
}

@Component({
  template: `
    <div mat-dialog-title>Änderungen vorhanden</div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>Möchten Sie die Änderungen verwerfen?</div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Verwerfen</button>
      <button mat-raised-button [mat-dialog-close]="false">Zurück</button>
    </div>
  `,
  styles: ['.mat-dialog-actions {float: right;}']
})
export class HasUnsavedChangesDialogComponent implements AfterViewInit {
  constructor(private elRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    let parentElement = this.elRef.nativeElement;
    let abort = 0;
    while (parentElement.parentNode.tagName.toLowerCase() !== 'body') {
      parentElement = parentElement.parentNode;
      if (abort++ > 10) {
        console.log('unable to patch parent class');
        return;
      }
    }
    parentElement.setAttribute('class', 'cdk-overlay-container changes-confirm-overlay-hack');
  }
}
