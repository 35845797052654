import {HasId} from '../../model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {_Service} from '../service/_service';

// tslint:disable:class-name
export class _ByQueryResolver<T extends HasId<I>, I> implements Resolve<T | null> {
  constructor(private service: _Service<T, I>, private name: string) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T | null> {
    const id = route.queryParamMap.get(this.name) as unknown as I;
    return id ? this.service.detail(id) : of(null);
  }
}
