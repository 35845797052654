import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {Holiday} from '../../model';
import {HolidayService} from '../service';

@Injectable()
export class HolidayListResolver extends _ListResolver<Holiday, Date> {
  constructor(router: Router, service: HolidayService) {
    super(router, service, 'hPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {hPage} = route.queryParams;
    return {page: hPage};
  }
}

