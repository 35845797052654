import {ChangeDetectorRef, Component, Inject, ViewChild} from '@angular/core';
import {Holiday} from '../../model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {AppAuthService} from '../auth';
import {MatInput} from '@angular/material/input';

@Component({templateUrl: 'holiday-detail-dialog.component.html', styleUrls: ['holiday-detail-dialog.component.scss']})
export class HolidayDetailDialogComponent {
  @ViewChild('form') form: NgForm;
  @ViewChild('desc') desc: MatInput;
  title: string;
  data: Holiday;

  constructor(public cdRef: ChangeDetectorRef, public authService: AppAuthService,
              private dialogRef: MatDialogRef<HolidayDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) {title, holiday}: any) {
    console.log(title);
    console.log(holiday);
    this.data = holiday;
    this.title = title;
//    this.dialogRef.afterOpened().subscribe(() => setTimeout(() => this.desc.focus()));
  }
}
