<mat-toolbar color="primary">
  <mat-toolbar-row *ngIf="authService.user; else noAuth">
    <div class="logo">&nbsp;</div>


    <a mat-button routerLink="/customer" routerLinkActive="active">Kunden</a>
    <a mat-button routerLink="/project" routerLinkActive="active">Projekte</a>
    <a mat-button routerLink="/timerecord/create" routerLinkActive="active">Zeiterfassung</a>
    <a mat-button routerLink="/extern" routerLinkActive="active">Berater</a>
    <a mat-button routerLink="/invoice" routerLinkActive="active" [disabled]="!authService.isAdmin">Rechnungen</a>
    <a mat-button routerLink="/user" routerLinkActive="active" [disabled]="!authService.isAdmin">Mitarbeiter</a>

    <span class="spacer"></span>

    <a [matMenuTriggerFor]="settings" mat-button routerLinkActive="active">
      Einstellungen
      <i class="mat-menu-item-submenu-trigger"> </i>
      <mat-menu #settings="matMenu" [overlapTrigger]="false">
        <a mat-menu-item routerLink="/holiday" routerLinkActive="active" [disabled]="!authService.isAdmin">Feiertage</a>
        <div mat-menu-item disabled>Version: {{version}}</div>
      </mat-menu>
    </a>

    <a [matMenuTriggerFor]="user" mat-button routerLinkActive="active">
      {{ authService.user.login }}
      <span *ngIf="!authService.user.login">&lt; admin ></span>
      <i class="mat-menu-item-submenu-trigger"> </i>
      <mat-menu #user="matMenu" [overlapTrigger]="false">
        <a mat-menu-item routerLink="/logout" routerLinkActive="active">Abmelden</a>
        <a mat-menu-item routerLink="/user/password" routerLinkActive="active">Passwort ändern</a>
      </mat-menu>
    </a>
  </mat-toolbar-row>

  <ng-template #noAuth>
    <mat-toolbar-row>
      <div class="logo">&nbsp;</div>
      <a mat-button routerLink="/login" routerLinkActive="active">Anmelden</a>
    </mat-toolbar-row>
  </ng-template>
</mat-toolbar>
