<h1 mat-dialog-title>Kontakt einfügen:</h1>

<mat-dialog-content>
  <mat-form-field appearance="fill" class="col-2_3" *ngIf="data.contacts.length>0">
    <mat-label>Bestehenden kontakt wählen</mat-label>
    <mat-select name="title" [(ngModel)]="contact">
      <mat-option [value]="u" *ngFor="let u of data.contacts">
        {{u.name}}, {{u.firstname}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button (click)="selectContact()" [disabled]="!contact">Hinzufügen</button>
  <h4>oder</h4>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="createContact()">
    <mat-icon>add_circle_outline</mat-icon>
    Neuen Kontakt Anlegen
  </button>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="undefined">Abbrechen</button>
</mat-dialog-actions>


