import {Injectable} from '@angular/core';
import {_ListAllResolver} from './_list-all.resolver';
import {UserService} from '../service';
import {User, UUID} from '../../model';

@Injectable()
export class UserAllResolver extends _ListAllResolver<User, UUID> {
  constructor(service: UserService) {
    super(service);
  }
}
