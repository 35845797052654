import {MatDialog} from '@angular/material/dialog';
import {HasId} from '../../model';
import {Observable} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {_Service} from '../service/_service';
import {ComponentType} from '@angular/cdk/overlay';

/* tslint:disable:class-name */
export abstract class _SelectDialog<T extends HasId<I>, I> {

  constructor(private dialog: MatDialog, private service: _Service<T, I>,
              private dialogComponent: ComponentType<any>) {
  }

  open(title: string, ignore: T[] = []): Observable<T> {
    const ids = ignore.map(e => e.id);
    return this.service
      .listAll().pipe(
        mergeMap((entries) => this.openFor(entries.filter(u => ids.indexOf(u.id) === -1), title)));
  }

  openFor(entries: T[], title: string): Observable<T> {
    return this.dialog.open(
      this.dialogComponent,
      {
        width: '750px',
        disableClose: false,
        data: {title, entries}
      })
      .beforeClosed()
      .pipe(filter(res => !!res));
  }
}

