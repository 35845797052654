import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {AppAuthService, InvoiceService, ProjectService} from '../service';
import {InvoiceListResolver} from './invoice-list.resolver';
import {Invoice, Page} from '../../model';
import {mergeMap, Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class InvoiceListForProjectResolver extends InvoiceListResolver {
  constructor(router: Router, service: InvoiceService, projectService: ProjectService, auth: AppAuthService) {
    super(router, {
      ...service,
      list(queryParams: Params): Observable<Page<Invoice>> {
        const {project} = queryParams;
        return auth.isAdmin$.pipe(
          take(1),
          mergeMap((admin) => admin
            ? service.list(queryParams)
            : projectService.isManager(project).pipe(
              take(1),
              mergeMap(({manager}) => manager
                ? service.list(queryParams)
                : of({} as any)
              ))
          ));
      }
    } as InvoiceService);
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {id: project} = route.params;
    return {...super.updateParameter(route), project};
  }

  protected override mapStatus(status?: string): string | undefined {
    return super.mapStatus(status || 'ALL');
  }
}
