import {Component} from '@angular/core';
import {AppAuthService} from './common/auth';
import {gitVersion} from '../version';

@Component({selector: 'app-main-nav', templateUrl: 'main-nav.component.html', styleUrls: ['main-nav.component.css']})
export class MainNavComponent {
    version = gitVersion;

    constructor(public authService: AppAuthService) {
    }
}
