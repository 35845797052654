<h1 mat-dialog-title>{{title}}</h1>
<h5>{{subtitle}}</h5>

<mat-dialog-content>
  <form #form="ngForm" novalidate autocomplete="off" (keydown.enter)="$event.preventDefault()" >
    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Anrede</mat-label>
        <mat-select name="title" [(ngModel)]="contact.title">
          <mat-option [value]="''"></mat-option>
          <mat-option [value]="'Herr'">Herr</mat-option>
          <mat-option [value]="'Frau'">Frau</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Titel</mat-label>
        <input matInput name="actitle" [(ngModel)]="contact.actitle" autotrim [matAutocomplete]="autoActitle"
               (ngModelChange)="updateActitleSuggestions()">
        <mat-autocomplete #autoActitle="matAutocomplete">
          <mat-option *ngFor="let s of actitleSuggestions" [value]="s">{{s}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Position / Role</mat-label>
        <input matInput name="role" [(ngModel)]="contact.role" autotrim [matAutocomplete]="autoRole"
               (ngModelChange)="updateRoleSuggestions()">
        <mat-autocomplete #autoRole="matAutocomplete">
          <mat-option *ngFor="let s of roleSuggestions" [value]="s">{{s}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Email</mat-label>
        <input matInput name="email" [(ngModel)]="contact.email" autotrim type="email">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Vorname</mat-label>
        <input matInput name="vorname" [(ngModel)]="contact.firstname" autotrim>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Name</mat-label>
        <input matInput name="name" [(ngModel)]="contact.name" #name="ngModel" autotrim required>
        <mat-error *ngIf="name.getError('required')">Eingabe erforderlich</mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Telefon</mat-label>
        <input matInput name="telefon" [(ngModel)]="contact.telefon" autotrim type="tel">
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Handy</mat-label>
        <input matInput name="handy" [(ngModel)]="contact.handy" autotrim type="tel">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-3_3">
        <mat-label>Info / Bemerkung</mat-label>
        <input matInput name="info" [(ngModel)]="contact.info" autotrim>
      </mat-form-field>
    </p>

    <p *ngFor="let d of details; index as i">
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Kontakt-Art</mat-label>
        <input [appCustomAutofocus]="doScroll" matInput [name]="'detail_k'+i" [(ngModel)]="d.name" #detail="ngModel"
               autotrim [required]="!!d.value" [matAutocomplete]="auto"
               (ngModelChange)="checkDuplicates(); d.updateSuggestions()">

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let s of d.suggestions" [value]="s">{{s}}</mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="detail.getError('required')">Eingabe erforderlich</mat-error>
        <mat-error *ngIf="detail.getError('duplicate')">Doppelter Eintrag nicht erlaubt</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label></mat-label>
        <input matInput [name]="'detail_v'+i" [(ngModel)]="d.value" (ngModelChange)="cdRef.detectChanges()" autotrim>
        <mat-icon (click)="removeItem(i)" matSuffix>close</mat-icon>
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="addDetail()">
    <mat-icon>add</mat-icon>
    weiterer Detail-Eintrag
  </button>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="undefined">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="onClose()" [disabled]="!form.dirty || form.invalid">
    Speichern
  </button>
</mat-dialog-actions>
