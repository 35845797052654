import {Injectable} from '@angular/core';
import {CustomerService} from '../service';
import {Customer, UUID} from '../../model';
import {_ByQueryResolver} from './_by-query.resolver';

@Injectable()
export class CustomerByIQueryResolver extends _ByQueryResolver<Customer, UUID> {
  constructor(service: CustomerService) {
    super(service, 'iCustomer');
  }
}
