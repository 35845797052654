import {UntypedFormControl} from '@angular/forms';
import {Observable, startWith} from 'rxjs';
import {map} from 'rxjs/operators';

export class AutocompleteMixin<T> {
  formControl = new UntypedFormControl();
  entries: Observable<T[]>;

  constructor(entries: T[], private filter: (entry: T, search: string) => boolean | undefined) {
    this.entries = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : '')),
      map(name => {
        if (name) {
          const filterValue = name.toLowerCase();
          return entries.filter((entry: T) => this.filter(entry, filterValue));
        } else {
          return entries.slice();
        }
      }),
    );
  }
}
