import {Injectable} from '@angular/core';
import {User, UUID} from '../../model';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../service';
import {UserSelectDialogComponent} from './user-select-dialog.component';
import {_SelectDialog} from './_select-dialog.service';

@Injectable()
export class UserSelectDialog extends _SelectDialog<User, UUID> {
  constructor(matDialog: MatDialog, service: UserService) {
    super(matDialog, service, UserSelectDialogComponent);
  }
}
