import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {_Service} from './_service';
import {Timerecord, User, UUID} from '../../model';
import {Observable} from 'rxjs';
import {buildQuery} from '../../util';
import {Params} from '@angular/router';

@Injectable()
export class TimerecordService extends _Service<Timerecord, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/timerecord');
  }

  createRecords = (data: {
    date: Date, holiday?: number, special?: number, sick?: number,
    entries: { project: UUID, hours?: number, distance?: number, special?: number, remarks?: string }[]
  }) => {
    return this.http.post<{ user: User, records: string[] }>(this.prefix, data);
  }

  summary(params: { user?: UUID, project?: string, customer?: string }): Observable<Timerecord> {
    return this.http.get<Timerecord>(this.prefix + '/summary' + buildQuery(params));
  }

  downloadExport(params: Params): Observable<HttpResponse<Blob>> {
    return this.http.get(this.prefix + '/export' + buildQuery(params), {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
