import {NgModule} from '@angular/core';
import {NoteResolver} from '../resolver';
import {NoteService} from '../service';
import {NoteListComponent} from './note-list.component';
import {CommonModule} from '../common.module';

@NgModule({
  declarations: [NoteListComponent],
  imports: [CommonModule],
  providers: [NoteService, NoteResolver],
  exports: [NoteListComponent],
})
export class NoteListModule {
}
