import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ContactService} from '../service';
import {Contact} from '../../model';
import {Observable} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {ContactDetailDialogComponent} from './contact-detail-dialog.component';

@Injectable()
export class ContactDetailDialog {

  constructor(private matDialog: MatDialog, private service: ContactService) {
  }

  editContact(title: string, subtitle: string, contact: Contact): Observable<Contact> {
    return this.service.listAutocompleteOptions().pipe(
      mergeMap(autocomplete => this.matDialog.open(ContactDetailDialogComponent,
        {
          width: '750px', disableClose: false,
          data: {title, subtitle, contact: {...contact}, autocomplete}
        })
        .beforeClosed()
      ),
      filter(res => !!res)
    );
  }
}
