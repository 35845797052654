<div [class.hide]="(loader.value$|async) === 0">
  <div class="loader-bar">
    <mat-progress-bar [value]="loader.value$|async" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="loader-spinner">
    <mat-progress-spinner [value]="loader.value$|async" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
<app-main-nav></app-main-nav>
<router-outlet></router-outlet>
