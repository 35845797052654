<h1 mat-dialog-title>{{title}}</h1>

<mat-dialog-content>
  <form #form="ngForm" novalidate autocomplete="off" (keydown.enter)="$event.preventDefault()">
    <p>
      <mat-form-field appearance="fill" class="col-15_3">
        <mat-label>Datum</mat-label>
        <input matInput name="date" [(ngModel)]="data.id" required type="date" [disabled]="!authService.isAdmin">
        <mat-error>Eingabe erforderlich</mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="fill" class="col-3_3">
        <mat-label>Beschreibung</mat-label>
        <input matInput name="desc" [(ngModel)]="data.desc" required [disabled]="!authService.isAdmin"
               #desc="matInput">
        <mat-error>Eingabe erforderlich</mat-error>
      </mat-form-field>
    </p>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="spacer"></div>
  <button mat-button [mat-dialog-close]="undefined">Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" [disabled]="!form.dirty || form.invalid">
    Speichern
  </button>
</mat-dialog-actions>
