import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {_DetailResolver} from './_detail.resolver';
import {InvoiceService} from '../service';
import {Invoice, UUID} from '../../model';

@Injectable()
export class InvoiceDetailResolver extends _DetailResolver<Invoice, UUID> {
  constructor(router: Router, toastr: ToastrService, service: InvoiceService) {
    super(router, toastr, service, 'invoice');
  }
}
