import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {Observable, of, OperatorFunction} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

export function redirectIfError<T>(path: string, router: Router, toastr: ToastrService): OperatorFunction<T, T> {
  return catchError((err): Observable<T> => {
    toastr.error(`failed loading Detail<br><br> ${err.message}`, 'Error!',
      {disableTimeOut: true});
    router.navigate([path]);
    return of();
  });
}

declare global {
  interface Array<T> {
    sum(this: Array<T>): number;
  }
}

Array.prototype.sum = function(this): number {
  return this.reduce((acc, now) => acc + now, 0);
};
