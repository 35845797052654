import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LoaderHttpXhrBackend} from './loader.http.xhr.backend';
import {HttpClientModule, HttpHandler} from '@angular/common/http';
import * as agcom from '@angular/common';
import {registerLocaleData} from '@angular/common';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {AppAuthService} from './auth';
import {UppercaseDirective} from './directives/uppercase.directive';
import {KeysPipe} from './directives/keys.directive';
import {LowercaseDirective} from './directives/lowercase.directive';
import {MatPaginatorIntlDe} from './paginator-intl';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {
  ContactService,
  CustomerService,
  CustomerTypeService,
  ExternService,
  HolidayService,
  InvoiceService,
  NoteService,
  ProjectService,
  TimerecordService,
  UserService
} from './service';
import {
  CustomerAllResolver,
  CustomerByIQueryResolver,
  CustomerByPQueryResolver,
  CustomerByProjectResolver,
  CustomerByTQueryResolver,
  CustomerDetailResolver,
  CustomerListResolver,
  CustomerTypeAllResolver,
  ExternDetailResolver,
  ExternListResolver,
  HolidayListResolver,
  InvoiceDetailResolver,
  InvoiceListResolver,
  NoteResolver,
  ProjectAllActiveResolver,
  ProjectByIQueryResolver,
  ProjectByTQueryResolver,
  ProjectDefaultResolver,
  ProjectDetailResolver,
  ProjectListResolver,
  ProjectUsersResolver,
  TagsResolver,
  TimerecordDetailResolver,
  TimerecordListForCustomerResolver,
  TimerecordListForProjectResolver,
  TimerecordListForUserResolver,
  TimerecordListResolver,
  TimerecordSummaryForCustomerResolver,
  TimerecordSummaryForProjectResolver,
  TimerecordSummaryForUserResolver,
  TimerecordSummaryResolver,
  UserAllManagerResolver,
  UserAllResolver,
  UserByIQueryResolver,
  UserByTQueryResolver,
  UserDetailResolver,
  UserListResolver,
  UserManagerByIQueryResolver
} from './resolver';
import {ConfirmDeleteDialogComponent} from './confirm-delete/confirm-delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AutotrimDirective} from './directives/autotrim.directive';
import {MatGridListModule} from '@angular/material/grid-list';
import {HasUnsavedChangesDialogComponent, PreventUnsavedChangesGuard} from './prevent-unsaved-changes-guard.service';
import {BackButtonDirective} from './directives/back-button.directive';
import {DuplicateCheckValidatorDirective} from './duplicate-check-validator.directive';
import {MatTabGroupSyncDirective} from './directives/mat-tab-group-sync.directive';
import {CustomAutofocusDirective} from './custom-autofocus.directive';
import {ContactSelectDialogComponent} from './contact/contact-select-dialog.component';
import {ConfirmDeleteDialog} from './confirm-delete/confirm-delete-dialog.service';
import {ContactSelectDialog} from './contact/contact-select-dialog.service';
import {ExternContactSelectDialogComponent} from './extern-contact-select/extern-contact-select-dialog.component';
import {ExternContactSelectDialog} from './extern-contact-select/extern-contact-select-dialog.service';
import {NumericInputDirective} from './directives/numeric-input.directive';
import {UserSelectDialogComponent} from './select-dialog/user-select-dialog.component';
import {ProjectSelectDialogComponent} from './select-dialog/project-select-dialog.component';
import {CustomerSelectDialogComponent} from './select-dialog/customer-select-dialog.component';
import {CustomerSelectDialog} from './select-dialog/customer-select-dialog.service';
import {UserSelectDialog} from './select-dialog/user-select-dialog.service';
import {ProjectSelectDialog} from './select-dialog/project-select-dialog.service';
import {ContactDetailDialog} from './contact/contact-detail-dialog.service';
import {UserProjectResolver} from './resolver/user-project.resolver';
import {InvoiceListForCustomerResolver} from './resolver/invoice-list-for-customer.resolver';
import {InvoiceDetailDialog} from './invoice/invoice-detail-dialog.service';
import {HolidayDetailDialog} from './holiday/holiday-detail-dialog.service';
import {InvoiceListForProjectResolver} from './resolver/invoice-list-for-project.resolver';
import {ProjectListForCustomerResolver} from './resolver/project-list-for-customer.resolver';
import {SortableFilterComponent} from './sortable-filter.component';
import {InvoiceDetailDialogComponent} from './invoice/invoice-detail-dialog.component';
import {HolidayDetailDialogComponent} from './holiday/holiday-detail-dialog.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    UppercaseDirective, LowercaseDirective, KeysPipe, ConfirmDeleteDialogComponent, HasUnsavedChangesDialogComponent,
    MatTabGroupSyncDirective, AutotrimDirective, NumericInputDirective,
    BackButtonDirective, DuplicateCheckValidatorDirective, CustomAutofocusDirective,

    ContactSelectDialogComponent, ExternContactSelectDialogComponent, ProjectSelectDialogComponent,
    CustomerSelectDialogComponent, UserSelectDialogComponent, SortableFilterComponent,
    InvoiceDetailDialogComponent, HolidayDetailDialogComponent
  ],
  imports: [
    agcom.CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatDividerModule, MatCardModule, MatNativeDateModule, MatButtonModule, MatButtonToggleModule, MatToolbarModule,
    MatSidenavModule, MatAutocompleteModule, MatTabsModule, MatGridListModule, MatFormFieldModule, MatIconModule,
    MatDialogModule, RouterModule, MatInputModule, MatSelectModule,
  ],
  providers: [
    AppAuthService, LoaderHttpXhrBackend,
    {provide: HttpHandler, useClass: LoaderHttpXhrBackend},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe},

    ConfirmDeleteDialog, PreventUnsavedChangesGuard,

    ContactDetailDialog, ContactSelectDialog, ExternContactSelectDialog, CustomerSelectDialog, ProjectSelectDialog,
    UserSelectDialog,

    ProjectService, ProjectListResolver, ProjectDetailResolver, ProjectDefaultResolver, ProjectAllActiveResolver,
    ProjectUsersResolver, ProjectListForCustomerResolver, ProjectByIQueryResolver, ProjectByTQueryResolver,
    TagsResolver,

    ContactService,
    CustomerService, CustomerListResolver, CustomerDetailResolver, CustomerAllResolver,
    CustomerByIQueryResolver, CustomerByPQueryResolver, CustomerByProjectResolver, CustomerByTQueryResolver,

    CustomerTypeService, CustomerTypeAllResolver,

    TimerecordService, TimerecordListResolver, TimerecordSummaryResolver, TimerecordDetailResolver,
    TimerecordListForCustomerResolver, TimerecordSummaryForCustomerResolver,
    TimerecordListForProjectResolver, TimerecordSummaryForProjectResolver,
    TimerecordListForUserResolver, TimerecordSummaryForUserResolver,

    InvoiceService, InvoiceListResolver, InvoiceDetailResolver, InvoiceListForCustomerResolver,
    InvoiceListForProjectResolver, InvoiceDetailDialog,

    UserService, UserListResolver, UserDetailResolver, UserAllResolver, UserAllManagerResolver,
    UserByIQueryResolver, UserManagerByIQueryResolver, UserByTQueryResolver, UserProjectResolver,

    ExternService, ExternListResolver, ExternDetailResolver,

    HolidayService, HolidayDetailDialog, HolidayListResolver,
    NoteService, NoteResolver,
  ],
  exports: [
    agcom.CommonModule, FormsModule, ReactiveFormsModule, RouterModule, HttpClientModule, MatProgressBarModule,
    MatProgressSpinnerModule, MatDividerModule, MatButtonModule, MatButtonToggleModule, MatMenuModule, MatCardModule,
    MatCheckboxModule, MatExpansionModule, MatChipsModule, MatNativeDateModule, MatIconModule, MatInputModule,
    MatPaginatorModule, MatTableModule, MatToolbarModule, MatSidenavModule, MatAutocompleteModule, MatSelectModule,
    MatTabsModule, MatDialogModule, MatGridListModule, UppercaseDirective, LowercaseDirective, KeysPipe,
    DuplicateCheckValidatorDirective, AutotrimDirective, NumericInputDirective, BackButtonDirective,
    MatTabGroupSyncDirective, CustomAutofocusDirective, SortableFilterComponent, InvoiceDetailDialogComponent,
    HolidayDetailDialogComponent
  ]
})
export class CommonModule {
}
