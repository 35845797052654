import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';
import {_ListResolver} from './_list.resolver';
import {ProjectService} from '../service';
import {Project, UUID} from '../../model';

@Injectable()
export class ProjectListResolver extends _ListResolver<Project, UUID> {
  constructor(router: Router, service: ProjectService) {
    super(router, service, 'pPage');
  }

  override updateParameter(route: ActivatedRouteSnapshot): Params {
    const {pPage, pOrder, pStatus, pCustomer, pStart, pFinish, pSearch, pManager} = route.queryParams;
    return {
      page: pPage, customer: pCustomer, start: pStart, finish: pFinish, search: pSearch, manager: pManager,
      order: this.mapOrder(pOrder),
      status: this.mapStatus(pStatus)
    };
  }

  protected mapOrder(order?: string): string | undefined {
    switch (order) {
      case undefined:
        return 'down_projekt_nr';
      default:
        return order;
    }
  }

  protected mapStatus(status?: string): string | undefined {
    switch (status) {
      case undefined:
        return 'INPROCESS';
      case 'ALL':
        return undefined;
      default:
        return status;
    }
  }
}
