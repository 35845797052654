import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import * as Sentry from '@sentry/browser';

@Injectable({providedIn: 'root'})
export class CustomErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    console.error(error);
    const userMessage = [];
    if (error.userMessage) {
      userMessage.push(error.userMessage);
    }

    if (error.rejection) {
      error = error.rejection;
    }

    if (error instanceof HttpErrorResponse) {
      this.handleErrorResponse(error, userMessage);
    } else {
      const eventId = Sentry.captureException(error.originalError || error);
      setTimeout(() => Sentry.showReportDialog({eventId}));

      this.injector.get(ToastrService).error(
        userMessage.join('<br/>\n') +
        error.message ? error.message : error.toString(), 'Unerwarteter Fehler', {disableTimeOut: true});
    }
  }

  private handleErrorResponse(response: HttpErrorResponse, userMessage: string[]): void {
    let msg = [...userMessage, response.statusText, response.error];
    let title = 'Server-Fehler ' + response.status;

    if (response.headers.get('content-type') === 'application/json') {
      const content = response.error;
      const lastMessage = content.detail && content.detail.length ? content.detail[content.detail.length - 1] : {};
      if (content.type === 'java.lang.RuntimeException') {
        msg.push(content.message);
      } else if (content.type && content.type.startsWith('javax.persistence.')) {
        msg.push(lastMessage.type + ' ' + lastMessage.message);
        title = 'Datenbank-Fehler';
      } else if (content.type === 'com.google.inject.extensions.security.NotInRoleException') {
        msg = lastMessage.message
          .replace('invalid role to access public ', '')
          .replace(new RegExp('Long', 'g'), 'L')
          .replace(new RegExp('long', 'g'), 'L')
          .replace(new RegExp('int', 'g'), 'I')
          .replace(new RegExp('java.lang.', 'g'), '')
          .replace(new RegExp('java.util.', 'g'), '');
        title = 'Fehlende Berechtigung';
      } else {
        msg.push(content instanceof String ? content.toString() : response.message);
        title = 'Server-Fehler';
      }
    }
    this.injector.get(ToastrService).error(msg.join('<br/>\n'), title, {disableTimeOut: true});
  }
}
