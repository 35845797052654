import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {_DetailResolver} from './_detail.resolver';
import {CustomerService} from '../service';
import {Customer, UUID} from '../../model';

@Injectable()
export class CustomerDetailResolver extends _DetailResolver<Customer, UUID> {
  constructor(router: Router, toastr: ToastrService, service: CustomerService) {
    super(router, toastr, service, 'customer');
  }
}
