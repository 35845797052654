import {HttpClient} from '@angular/common/http';
import {Params} from '@angular/router';
import {HasId, Page} from '../../model';
import {buildQuery} from '../../util';

export const url = (...segment: string[]) => segment.join('/');

/* tslint:disable:class-name */
export class _Service<T extends HasId<I>, I> {

  constructor(protected http: HttpClient, protected prefix: string) {
  }

  mapId = (f: I): string => (f as any);

  list = (queryParams: Params) => this.http.get<Page<T>>(this.prefix + buildQuery(queryParams));

  listAll = (queryParams: Params = {}) => this.http.get<T[]>(url(this.prefix, 'all') + buildQuery(queryParams));

  detail = (id: I) => this.http.get<T>(url(this.prefix, this.mapId(id)));

  update = (id: I, data: T) => this.http.put<T>(url(this.prefix, this.mapId(id)), {...data});

  create = (data: T) => this.http.post<T>(this.prefix, {...data});

  delete = (data: T) => this.http.delete<T>(url(this.prefix, this.mapId(data.id)));
}
