import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {_Service, url} from './_service';
import {Contact, Extern, UUID} from '../../model';
import {Observable} from 'rxjs';

@Injectable()
export class ExternService extends _Service<Extern, UUID> {
  constructor(http: HttpClient) {
    super(http, 'api/extern');
  }

  listAllExtern = () =>
    this.http.get<Extern[]>(url(this.prefix, 'all'))

  addContact(extern: Extern, contact: Contact): Observable<any> {
    return this.http.post<any>(`${this.prefix}/${extern.id}/contact`, {...contact});
  }

  removeContact(extern: Extern, contact: Contact): Observable<any> {
    return this.http.delete<any>(`${this.prefix}/${extern.id}/contact/${contact.id}`);
  }
}
