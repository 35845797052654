import {ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from '@sentry/browser';

import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {AppComponent} from './app.component';
import {MainNavComponent} from './main-nav.component';
import {CommonModule} from './common';
import {NotFoundComponent} from './not-found.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthRoutingModule} from './common/auth/auth-routing.module';
import {CustomErrorHandler} from './common/custom-error.handler';
import {LOADING_BAR_CONFIG, LoadingBarModule} from '@ngx-loading-bar/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';

Sentry.init({
  dsn: 'https://186e62f76358447aa5203a1d06f82c02@o141055.ingest.sentry.io/5556901',
});

@NgModule({
  declarations: [MainNavComponent, AppComponent, NotFoundComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      enableHtml: true,
      positionClass: 'toast-bottom-right'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AuthRoutingModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
//    {provide: HttpHandler, useClass: LoaderHttpXhrBackend},
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: LOADING_BAR_CONFIG, useValue: {latencyThreshold: 100}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
